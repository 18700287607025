import styles from "./App.module.css";
import LogoContainer from "./component/LogoContainer/LogoContainer";
import { Box, createTheme, ThemeProvider } from "@mui/material";
import Routers from "./route/Routers";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import RoutersDelete from "./route/RoutersDelete";

function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#CF0000",
      },
    },
  });
  const [searchParams] = useSearchParams();
  const backUrl = searchParams.get("cancel");
  const goBack = () => {
    window.location.replace(backUrl);
  };
  // const { isLogo } = useSelector((state) => state.homepage);
  // return (
  //   <div className={styles.App}>
  //     <ThemeProvider theme={theme}>
  //       <Box className={styles.appBox}>
  //         {isLogo && (
  //           <>
  //             <div className={styles.headerContainer}>
  //               <a
  //                 style={{ cursor: "pointer", zIndex: 99 }}
  //                 onClick={() => goBack()}
  //               >
  //                 <ArrowBackIcon sx={{ color: "#CF0000", fontSize: "30px" }} />
  //               </a>
  //             </div>
  //             <LogoContainer />
  //           </>
  //         )}
  //         <Routers />
  //       </Box>
  //     </ThemeProvider>
  //   </div>
  // );
  const deleteAcc = window.location.href.split("/")[window.location.href.split("/").length-1]
  if(window.location.href.split("/")[3] === "delete"){
    return (
      <div className={styles.App}>
        <ThemeProvider theme={theme}>
          {deleteAcc ?
          <RoutersDelete/>
        :
          <Box className={styles.appBox}>
            <div className={styles.headerContainer}>
              <a
                style={{cursor: "pointer", zIndex: 99}}
                onClick={() => goBack()}
              >
                <ArrowBackIcon sx={{color: "#CF0000", fontSize: "30px"}}/>
              </a>
            </div>
            <LogoContainer/>
            <Routers/>
          </Box>
        }
        </ThemeProvider>
      </div>
    );
  }else{
    return (
      <div className={styles.App}>
        <ThemeProvider theme={theme}>
          <Box className={styles.appBox}>
            <div className={styles.headerContainer}>
              <a
                style={{ cursor: "pointer", zIndex: 99 }}
                onClick={() => goBack()}
              >
                <ArrowBackIcon sx={{ color: "#CF0000", fontSize: "30px" }} />
              </a>
            </div>
            <LogoContainer />
            <Routers />
          </Box>
        </ThemeProvider>
      </div>
    );
  }
}

export default App;
