import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import ErrorNotFound from "../component/error/ErrorNotFound";
import Login from "../component/Login/Login";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import Migrations from "../component/Migration/Migrations";
import WebValidate from "../component/WebValidate/WebValidate";
import RegisterInvitation from "../component/RegisterInvitation/RegisterInvitation";
import EmailVerificationSuccess from "../component/EmailVerification/EmailVerificationSuccess";
import Delete2 from "../component/Delete/Delete2"

export default function Routers() {
  // Redux State
  const isValidated = useSelector(
    (state) => state.validateData.data.isValidated
  );

  const PrivateRoute = ({ children }) => {
    return isValidated ? children : <Navigate to="/login" />;
  };
  PrivateRoute.propTypes = {
    children: PropTypes.object,
  };
  return (
    <Routes>
      <Route exact path="/login" element={<Login />} />
      <Route exact path="/migrations" element={<Migrations />} />
      <Route exact path="/validate" element={<WebValidate />} />
      <Route exact path="/register" element={<RegisterInvitation />} />
      <Route
        exact
        path="/email-verification"
        element={<EmailVerificationSuccess />}
      />
      <Route path="/" element={<ErrorNotFound />} />
      <Route path="/*" element={<ErrorNotFound />} />
    </Routes>
  );
}
